<template>
    <img
        src="@/assets/images/reload.svg"
        height="15"
        width="15"
        alt="reload img"
    />
</template>

<script>
export default {
    name: "ReloadIcon"
};
</script>
